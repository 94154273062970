<template>
  <Layout>
    <div class="row mt-2">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-tabs>
              <b-tab title="All Transactions">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="transactionData"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                    <template #cell(name)="row">
                      {{ row.item.bank_account.name }}
                    </template>
                    <template #cell(type)="row">
                      {{ row.item.transaction_type }}
                    </template>
                    <template #cell(status)="row">
                      {{
                        row.item.status == 1
                          ? "Requested"
                          : row.item.status == 0
                          ? "Rejected"
                          : row.item.status == 2
                          ? "Approved"
                          : "Earning"
                      }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.value).format("lll") }}
                    </template>
                    <template #cell(actions)="row">
                      <b-dropdown v-if="row.item.status === 1">
                        <template v-slot:button-content>
                          Actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item @click="approve(row.item)"
                          >Approve</b-dropdown-item
                        >
                        <b-dropdown-item @click="reject(row.item)"
                          >Reject</b-dropdown-item
                        >
                      </b-dropdown>
                      <b-dropdown disabled v-else>
                        <template v-slot:button-content>
                          Actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
              </b-tab>
              <b-tab title="Requested">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="requestedTransactions"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                    <template #cell(name)="row">
                      {{ row.item.bank_account.name }}
                    </template>
                    <template #cell(type)="row">
                      {{ row.item.transaction_type }}
                    </template>
                    <template #cell(status)="row">
                      {{
                        row.item.status == 1
                          ? "Requested"
                          : row.item.status == 0
                          ? "Rejected"
                          : row.item.status == 2
                          ? "Approved"
                          : "Earning"
                      }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.value).format("lll") }}
                    </template>
                    <template #cell(actions)="row">
                      <b-dropdown v-if="row.item.status === 1">
                        <template v-slot:button-content>
                          Actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                        <b-dropdown-item @click="approve(row.item)"
                          >Approve</b-dropdown-item
                        >
                        <b-dropdown-item @click="reject(row.item)"
                          >Reject</b-dropdown-item
                        >
                      </b-dropdown>
                      <b-dropdown disabled v-else>
                        <template v-slot:button-content>
                          Actions
                          <i class="mdi mdi-chevron-down"></i>
                        </template>
                      </b-dropdown>
                    </template>
                  </b-table>
                </div>
              </b-tab>
              <b-tab title="Approved Transactions">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="approvedTransactions"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                    <template #cell(name)="row">
                      {{ row.item.bank_account.name }}
                    </template>
                    <template #cell(type)="row">
                      {{ row.item.transaction_type }}
                    </template>
                    <template #cell(status)="row">
                      {{
                        row.item.status == 1
                          ? "Requested"
                          : row.item.status == 0
                          ? "Rejected"
                          : row.item.status == 2
                          ? "Approved"
                          : "Earning"
                      }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.value).format("lll") }}
                    </template>
                  </b-table>
                </div>
              </b-tab>
              <b-tab title="Rejected Transactions">
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Show&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entries
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Search:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive mb-0">
                  <b-table
                    :items="rejectedTransactions"
                    :fields="fields"
                    responsive="sm"
                    :busy="isBusy"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template #table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                      </div>
                    </template>
                    <template #cell(name)="row">
                      {{ row.item.bank_account.name }}
                    </template>
                    <template #cell(type)="row">
                      {{ row.item.transaction_type }}
                    </template>
                    <template #cell(status)="row">
                      {{
                        row.item.status == 1
                          ? "Requested"
                          : row.item.status == 0
                          ? "Rejected"
                          : row.item.status == 2
                          ? "Approved"
                          : "Earning"
                      }}
                    </template>
                    <template #cell(createdAt)="row">
                      {{ moment(row.value).format("lll") }}
                    </template>
                  </b-table>
                </div>
              </b-tab>
            </b-tabs>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      @change="updatePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { txn } from "@/config/api/txns";
import Layout from "@/views/layouts/main";

export default {
  components: {
    Layout,
  },
  props: ["reload"],
  data() {
    return {
      transactionData: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      fields: [
        { key: "name", sortable: false },
        { key: "amount", sortable: false },
        { key: "type", sortable: false },
        { key: "status", sortable: true },
        { key: "createdAt", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.getAllTransactions();
  },
  computed: {
    rows() {
      return this.transactionData.length;
    },
    requestedTransactions() {
      return this.transactionData.filter((item) => item.status === 1);
    },
    approvedTransactions() {
      return this.transactionData.filter((item) => item.status === 2);
    },
    rejectedTransactions() {
      return this.transactionData.filter((item) => item.status === 0);
    },
  },
  watch: {
    reload(val) {
      if (val) {
        this.getAllTransactions();
      }
    },
  },

  methods: {
    approve(data) {
      console.log(data);
      const api = txn.approve;
      api.id = data._id;
      this.generateAPI(api)
        .then(() => {
          this.getAllTransactions();
          this.$bvToast.toast("Transaction Approved!", {
            title: "Transaction Approved",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Transaction Approved!", {
            title: "Unable to update the status, Try again!",
            variant: "danger",
            solid: true,
          });
        });
    },
    reject(data) {
      const api = txn.reject;
      api.id = data._id;
      this.generateAPI(api)
        .then(() => {
          this.getAllTransactions();
          this.$bvToast.toast("Transaction Rejected!", {
            title: "Transaction Rejected",
            variant: "success",
            solid: true,
          });
        })
        .catch(() => {
          this.$bvToast.toast("Transaction Rejected!", {
            title: "Unable to update the status, Try again!",
            variant: "danger",
            solid: true,
          });
        });
    },
    updatePage(page) {
      console.log("Test");
      this.getAllTransactions(page);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllTransactions() {
      this.isBusy = true;
      const api = txn.get;
      this.generateAPI(api)
        .then((res) => {
          this.transactionData = res.data;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.isBusy = false;
          this.$emit("dataLoaded");
        });
    },
  },
};
</script>
<style>
.dropdown-item {
  color: #5d6071;
}
</style>
